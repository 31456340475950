import { Link } from 'react-router-dom'
import AwesomeIcon from '../core/AwesomeIcon'
import { WorkshopCTAForm } from '../core/ConvertKit'
import PageTitle from './PageTitle'

import findingCommonGroundImage from '../images/workshops/finding-common-ground.jpg'
import momBurnoutImage from '../images/workshops/navigating-mom-burnout.jpg'

function Workshop({
  title = 'Defining Enough',
  subtitle = 'Workshop and Coaching',
  description = "Defining Enough is an 8-week workshop that includes education, as well as group and individual coaching to support you as you define your 'enough'.",
  link = '/workshops/defining-enough',
  image,
  children,
}) {
  return (
    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-none">
      <Link to={link} className="block mt-2 hover:no-underline">
        <div className="flex flex-row rounded-lg shadow-lg overflow-hidden">
          <div className="shrink-0">
            <img className="w-96 h-full object-cover" src={image} alt={title} />
          </div>
          <div className="bg-white p-6 flex flex-col justify-between text-gray-500">
            <div>
              <p className="text-sm font-medium text-brand-600">{subtitle}</p>
              <p className="text-xl font-semibold text-gray-900">{title}</p>
              {description}
              {children}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

function WorkshopVertical({
  title,
  subtitle,
  description,
  link,
  image,
  children,
}) {
  return (
    <a
      href={link}
      target="_blank"
      className="block mt-2 mb-4 hover:no-underline"
    >
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden w-[40rem]">
        <div className="flex-shrink-0">
          <img
            className="h-[40rem] w-full object-cover"
            src={image}
            alt={title}
          />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1 text-gray-500">
            <p className="text-sm font-medium text-brand-600">{subtitle}</p>
            <p className="text-xl font-semibold text-gray-900">{title}</p>
            {description}
            {children}
          </div>
        </div>
      </div>
    </a>
  )
}

function WorkshopCards() {
  const list = [
    // {
    //   link: 'https://www.eventbrite.com/e/482651723297',
    //   title: 'Emotional Resilience Workshop',
    //   subtitle: 'virtual',
    //   description:
    //     'Workshop is 8 sessions every Wednesday at 10am Pacific starting January 11 2023. Attend any or all of the sessions. Participation in all 8 sessions is not required.',
    //   image: emotionalResWorkshopImage,
    //   children: <b className="block mt-2">Click here to register!</b>,
    // },
    // {
    //   link: 'https://calendly.com/exploreyou/commyounication-complimentary-workshop',
    //   title: 'CommYOUnication Webinar',
    //   subtitle: 'virtual',
    //   description:
    //     'This 60 minute webinar will provide you with tools, options and resources to up your communication capabilities and effectiveness.',
    //   image: commWebinarImage,
    //   children: <b className="block mt-2">Click here to register!</b>,
    // },
    {
      link: 'https://calendly.com/exploreyou/navigating-mom-burnout-workshop-with-coaching',
      title: 'Navigating Mom Burnout',
      subtitle: 'live - virtual - recorded',
      description: (
        <>
          <p className="text-sm mb-4">
            $50
            <AwesomeIcon
              prefix={'fas'}
              icon={'calendar'}
              className="text-xs mx-2"
            />
            Saturday, November 18, 2023
            <AwesomeIcon
              prefix={'fas'}
              icon={'stopwatch'}
              className="text-xs mx-2"
            />
            4-7PM Pacific Time
          </p>
          <p className="mb-2">
            Are you a mom feeling overwhelmed, exhausted, and on the brink of
            burnout?
          </p>
          <p className="mb-2">
            Mom Burnout is real. Many mothers experience burnout and often don't
            know that they are burned out. In addition, many of us who feel
            burned out at times feel guilty because of it and blame ourselves
            for not being able to 'handle it all' gracefully.
          </p>
          <p className="mb-2">
            This workshop will not take away the potential for burnout, burnout
            will happen. What this workshop does is offers personalized support,
            insights, and practical tools to help as you navigate your symptoms
            and causes of burnout before it turns into breakdown.
          </p>
          <p className="mb-2">
            The Navigating “Mom Burnout” workshop includes:
          </p>

          <p className="mb-2">
            <b>Understanding Burnout:</b> Being a mom is a rewarding experience,
            but it can also be incredibly demanding. Juggling multiple
            responsibilities, managing a household, and nurturing your family
            can leave you feeling drained. Burnout is a real challenge, and it's
            crucial to understand its causes and signs.
          </p>
          <p className="mb-2">
            <b>Empowerment Through Knowledge:</b> This workshop is designed to
            empower you with knowledge about the reasons behind burnout. We'll
            explore the unique stressors faced by mothers, such as the pressures
            of caregiving, work-life balance, and societal expectations.
            Understanding these triggers is the first step toward combating
            burnout.
          </p>
          <p className="mb-2">
            <b>Practical Tools and Strategies:</b> Your time is precious and
            limited. That's why this workshop focuses on practical tools and
            strategies that you can immediately incorporate into your daily
            life. From time management techniques to stress reduction methods,
            you'll gain valuable insights that can make a significant
            difference.
          </p>
          <p className="mb-2">
            <b>Creating a Self-Care Plan:</b> One-size-fits-all self-care plans
            often fall short. We'll help you create a personalized and realistic
            self-care plan tailored to your unique needs and circumstances. Your
            well-being matters, and we'll guide you in making self-care a
            sustainable part of your routine.
          </p>
          <p className="mb-2">
            <b>A Supportive Community:</b> In this workshop, you'll join a
            community of fellow moms who understand the challenges you face.
            Sharing experiences and learning from others can be a powerful
            source of strength and inspiration on your journey to combat
            burnout.
          </p>
          <p className="mb-2">
            <b>Invest in Yourself:</b> Remember, taking care of yourself is not
            a luxury; it's a necessity. Join our workshop, and together, we'll
            combat burnout, prioritize your well-being, and help you become the
            resilient, empowered mom you want to be.
          </p>
          <p className="mb-2">
            <b>1 Hour of Personalized Coaching:</b> All participants will have
            the opportunity for 1 hour of professional coaching provided by
            Jaime. Coaching can be done in 2 sessions of 30 minutes, or 1
            60-minute session. Coaching will support you in turning what you
            learn in the workshop into actionable steps personalized to you,
            creating sustainable change.
          </p>
          <p className="mb-2">
            Don't wait until burnout takes its toll! Join us in this
            transformative journey towards combating burnout and experiencing a
            healthier, happier you. Moms in all seasons of motherhood are
            welcomed.
          </p>
          <p className="mb-2 text-sm">
            Note: Recording, materials, and scheduling link for coaching will be
            provided via email to all participants, even if you weren't able to
            make it to the live session.
          </p>
        </>
      ),
      image: momBurnoutImage,
      children: <b className="block mt-2">Click here to register!</b>,
    },
    {
      link: 'https://calendly.com/exploreyou/finding-common-ground-overcoming-common-communication-challenges-workshop-coaching',
      title:
        'Finding Common Ground - Overcoming Common Communication Challenges Workshop and Coaching',
      subtitle: 'live - virtual - recorded',
      description: (
        <>
          <p className="text-sm mb-4">
            $50
            <AwesomeIcon
              prefix={'fas'}
              icon={'calendar'}
              className="text-xs mx-2"
            />
            Saturday, November 11, 2023
            <AwesomeIcon
              prefix={'fas'}
              icon={'stopwatch'}
              className="text-xs mx-2"
            />
            4-7PM Pacific Time
          </p>
          <p className="mb-2">
            Tired of having the same counterproductive conversations that seem
            to lead nowhere and change nothing?
          </p>
          <p className="mb-2">
            Want to build stronger, healthier relationships by breaking free
            from ineffective communication patterns?
          </p>
          <p className="mb-2">
            The Finding Common Ground Workshop introduces you a transformative
            process to do just that.
          </p>
          <p className="mb-2">
            <b>Communication Challenges Unveiled:</b> We all encounter
            communication challenges in our personal and professional lives.
            It's frustrating when conversations become repetitive, filled with
            arguments about who's 'right,' or result in shutting down,
            defensiveness, and judgment. But here's the good news - these
            patterns can be changed!
          </p>
          <p className="mb-2">
            <b>Unlock Effective Communication:</b> In our workshop, you'll learn
            a proven process to truly connect with others, even in the most
            difficult conversations. I will provide practical skills and
            personalized coaching that can be applied immediately.
          </p>
          <p className="mb-2">
            <b>Break the Cycle:</b> Are you tired of feeling like you're stuck
            in a never-ending loop of the same conversation repeatedly with
            counterproductive results? I will show you how to break free from
            this cycle. Learn to manage common challenges and patterns including
            'right vs. wrong', shutting down, defensiveness and judgment.
            Instead, learn techniques to facilitate compassionate and respectful
            dialogue.
          </p>
          <p className="mb-2">
            <b>Strengthen Your Relationships:</b> Effective communication is the
            cornerstone of strong, healthy, and resilient relationships. Whether
            you're dealing with conflicts at home, work, or in your social
            circles, this workshop will empower you to build bridges, foster
            understanding, and nurture connections.
          </p>
          <p className="mb-2">
            <b>Expert Facilitator:</b> Jaime has a passion for helping others
            communicate better and has done extensive study in communication and
            conflict resolution including a BA in Communication and a Graduate
            Certificate in Mediation. She is skilled in helping individuals and
            groups navigate communication challenges and brings a wealth of
            knowledge, practical insights, and a compassionate approach to guide
            you on your journey to finding common ground.
          </p>
          <p className="mb-2">
            <b>A Community of Support:</b> Join a community of like-minded
            individuals who share your desire for better communication. Connect,
            share experiences, and learn from one another as you work together
            to elevate your communication skills and improve your relationships.
          </p>
          <p className="mb-2">
            <b>1 Hour of Personalized Coaching:</b> All participants will have
            the opportunity for 1 hour of professional coaching provided by
            Jaime. Coaching can be done in 2 sessions of 30 minutes, or 1
            60-minute session. Coaching will support you in turning what you
            learn in the workshop into actionable steps personalized to you,
            creating sustainable change.
          </p>
          <p className="mb-2">
            <b>Invest in Your Relationships:</b> Effective communication is an
            investment in your relationships, your well-being, and your personal
            growth. By participating in "Finding Common Ground," you're taking a
            proactive step towards creating healthy, resilient relationships
            with yourself and others.
          </p>
          <p className="mb-2">
            Don't let counterproductive conversations hold you back any longer.
            Break free from old patterns, connect with others on a deeper level,
            and strengthen your relationships. Join us in the transformative
            journey of "Finding Common Ground, Overcoming Common Communication
            Challenges".
          </p>
          <p className="text-sm">
            Note: Recording, materials, and scheduling link for coaching will be
            provided via email to all participants, even if you weren't able to
            make it to the live session.
          </p>
        </>
      ),
      image: findingCommonGroundImage,
      children: <b className="block mt-2">Click here to register!</b>,
    },
  ]
  const workshops = list.map(w => (
    <WorkshopVertical
      key={w.title}
      link={w.link}
      title={w.title}
      subtitle={w.subtitle}
      description={w.description}
      image={w.image}
      children={w.children}
    />
  ))
  return (
    <div className="relative py-10 px-4 sm:px-6 lg:px-8">
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <PageTitle text="Upcoming Workshops" />
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            All workshops are open to everyone. Cost and pre-requisites are
            listed in the event details.
          </p>
        </div>
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="flex flex-wrap justify-center mt-10">{workshops}</div>
      </div>
      <WorkshopCTAForm />
    </div>
  )
}

export default function Workshops() {
  return <WorkshopCards />
}
