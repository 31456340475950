import Markdown from 'marked-react'
import renderer from '../renderer'
import PricingTable from './PricingTable'
import coachingPage from 'bundle-text:./conflict_management.md'
import PageTitle from './PageTitle'
import ScheduleCta from '../core/ScheduleCta'

const groupOptions = [
  {
    title: 'Complementary Consultation Session',
    subtitle: 'A 90 minute individual consultation to discuss options',
    cost: 'FREE',
    something: '',
    buttonText: 'Schedule Free Consutlation',
    buttonLink: 'https://calendly.com/exploreyou/coaching-introductory-call',
    options: [
      'An individual 1-on-1 consultation session for perspective clients',
    ],
  },
  {
    title: 'One-One Coaching for Individuals',
    subtitle: 'A single 60 minute individual coaching session',
    cost: '$45',
    something: '/hr',
    buttonText: 'Schedule Free Consutlation',
    buttonLink: 'https://calendly.com/exploreyou/coaching-introductory-call',
    optionsTitle: 'Individual coaching sessions provide a space to:',
    options: [
      'Determine needs',
      'Determine wants',
      'Prepare for a difficult conversation',
      'Explore the issue in a confidential and non-judgmental space',
    ],
  },
  {
    title: 'Group Coaching Session',
    subtitle: '60 minute group coaching session, 2-8 participants',
    cost: '$30',
    something: '/hr per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/exploratory-discussion-group-and-or-individual-coaching',
    optionsTitle: 'Group Coaching Sessions provide a space to:',
    options: [
      'Determine individual needs and wants',
      'Determine group needs and wants',
      'Have a difficult conversation if applicable in a safe environment',
      'Explore the issue in a neutral, confidential and non-judgmental space',
    ],
  },
  {
    title: 'Mediation',
    subtitle:
      'Mediation is suggested for any conflict that is at an impasse and the same conversation is had over and over and not moving anything forward.  The parties involved may be exhausted and the relationship is deteriorating.',
    cost: '$75',
    something: '/hr',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/customized-workshops-w-coaching-discussion',
    optionsTitle: 'Mediation provides',
    options: [
      'A formal process where 2 or more people are willing to sit down and explore and work through the issue with a neutral third party',
      'Provide a safe, confidential, and non-judgmental space to work towards everyone getting their needs met',
    ],
  },
  {
    title: 'Energy Leadership Index Assessment with Conflict Focus',
    subtitle: 'For individuals teams and groups',
    cost: '$250',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/assessment-eli-eq-disc-discussion',
    options: [
      'Take Energy Leadership Index Assessment to determine how you approach conflict',
      'Debrief of the assessment (Approximately 2 hours)',
      '2 Individual Coaching Sessions for each participant (60 minutes each)',
      '1 hour of live virtual training and discussion about turning conflict into an opportunity',
    ],
  },
  {
    title: 'Emotional Intelligence Assessment with Conflict Focus',
    subtitle:
      'Taking FLight with EQ (Emotional Intelligence) Assessment, plus DISC Assessment, with Coaching',
    cost: '$250',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/assessment-eli-eq-disc-discussion',
    options: [
      'Take Emotional Intelligence Assessment to determine your EQ (emotional intelligence) approach.',
      'Individual debrief of the assessment (Approximately 1 hour per participant)',
      '2 Individual Coaching Sessions for each participant (60 minutes each)',
      '1 hour of live virtual training and discussion about turning conflict into an opportunity',
    ],
  },
  {
    title:
      'Conflict Resolution Training with Coaching - Individuals and Teams/ Groups',
    subtitle: 'Conflict resolution skills training',
    cost: '$300',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/customized-workshops-w-coaching-discussion',
    options: [
      '2 hours of live virtual training on Conflict as an Opportunity and Productive approaches to conflict. Training will be in 2 sessions of 1 hour each.',
      '4 hours of individual coaching per participant - 2 after 1st training session and 2 following the final training session',
    ],
  },
]

export default function ConflictManagement() {
  return (
    <div className="px-5">
      <PageTitle text="Effective Conflict Management" />
      <Markdown value={coachingPage} renderer={renderer} />
      <PricingTable
        title="Conflict Management Offerings"
        groupOptions={groupOptions}
      />
      <ScheduleCta
        callToAction="Want to have a new conversation?"
        subTitle="I can help you!"
      />
    </div>
  )
}
