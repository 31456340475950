import Markdown from 'marked-react'
import renderer from '../renderer'
import bookClubText from 'bundle-text:./book_club.md'
import bookClubText2 from 'bundle-text:./book_club2.md'
import emotionalAgilityBook from '../images/book_club/emotional-agility.jpg'

export default function BookClub() {
  return (
    <>
      <Markdown value={bookClubText} renderer={renderer} />
      <div>
        <img className="h-96" src={emotionalAgilityBook} alt="" />
        <ul className="list-disc my-8 mx-4 text-xl">
          <li>#1 Wall Street Journal Best Seller</li>
          <li>USA Today Best Seller</li>
          <li>Amazon Best Book of 2016</li>
        </ul>
      </div>
      <Markdown value={bookClubText2} renderer={renderer} />
    </>
  )
}
