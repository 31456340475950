export default function ScheduleCta({
  url = 'https://calendly.com/exploreyou/coaching-introductory-call',
  callToAction = 'Want to explore your story?',
  subTitle = "Let's talk about it!",
}) {
  return (
    <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        <span className="block">{callToAction}</span>
        <span className="block mt-2">{subTitle}</span>
      </h2>
      <div className="mt-8 flex justify-center">
        <div className="inline-flex rounded-md shadow">
          <a
            href={url}
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-50 text-center bg-brand-600 hover:bg-brand-500 hover:text-gray-50 hover:no-underline"
          >
            Schedule Free Consultation
          </a>
        </div>
      </div>
    </div>
  )
}
