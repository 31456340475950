import coachingPage from 'bundle-text:./coaching.md'
import Markdown from 'marked-react'
import renderer from '../renderer'
import PageTitle from './PageTitle'
import PricingTable from './PricingTable'

const groupOptions = [
  {
    title: 'Complementary Consultation Session',
    subtitle: 'A 90 minute individual consultation to discuss options',
    cost: 'FREE',
    something: '',
    buttonText: 'Schedule Free Consutlation',
    buttonLink: 'https://calendly.com/exploreyou/coaching-introductory-call',
    options: [
      'An individual 1-on-1 consultation session for perspective clients',
    ],
  },
  {
    title: 'One-One Coaching for Individuals',
    subtitle: 'A single 60 minute individual coaching session',
    cost: '$75',
    something: '/hr',
    buttonText: 'Schedule Free Consutlation',
    buttonLink: 'https://calendly.com/exploreyou/coaching-introductory-call',
    optionsTitle: 'Individual coaching sessions provide a space to:',
    options: [
      'Increase Self Awareness (the key to an authentic, effective life)',
      'Increase personal effectiveness',
      'Remove inner roadblocks and barriers',
      'Lead authentically',
      'Create an action plan to fulfill individual goals and needs',
      'Accountability',
      'Explore ideas and approaches to everyday challenges',
      'Explore any issue in a confidential and non-judgmental space',
    ],
  },
  {
    title: 'Group Coaching Session',
    subtitle: '60 minute group coaching session, 2-8 participants',
    cost: '$45',
    something: '/hr per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/exploratory-discussion-group-and-or-individual-coaching',
    optionsTitle: 'Group Coaching Sessions provide a space to:',
    options: [
      'Increase Self Awareness (the key to an authentic, effective life)',
      'Increase personal and group effectiveness',
      'Remove roadblocks and barriers',
      'Work from authenticity',
      'Create an action plan to fulfill individual and group needs',
      'Accountability',
      'Explore ideas and approaches to everyday challenges',
      'Explore any group issue in a confidential and non-judgmental space',
    ],
  },
  {
    title: 'Energy Leadership Index Assessment',
    subtitle: 'Identify what engergy you bring to life situations',
    cost: '$200',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/assessment-eli-eq-disc-discussion',
    options: [
      'Take Energy Leadership Index Assessment to determine how you approach life situations',
      'Debrief of the assessment (Approximately 2 hours)',
      '2 Individual Coaching Sessions for each participant (60 minutes each)',
    ],
  },
  {
    title: 'Emotional Intelligence Assessment',
    subtitle:
      'Taking FLight with EQ (Emotional Intelligence) Assessment, plus DISC Assessment, with Coaching',
    cost: '$200',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/assessment-eli-eq-disc-discussion',
    options: [
      'Take Emotional Intelligence Assessment to determine your EQ (emotional intelligence) approach.',
      'Individual debrief of the assessment (Approximately 1 hour per participant)',
      '2 Individual Coaching Sessions for each participant (60 minutes each)',
    ],
  },
]

export default function Coaching() {
  return (
    <div className="px-5">
      <PageTitle text="Personal Effectiveness Coaching" />
      <Markdown value={coachingPage} renderer={renderer} />
      <PricingTable title="Coaching Offerings" groupOptions={groupOptions} />
    </div>
  )
}
