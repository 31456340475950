import { Routes, Route } from 'react-router-dom'

import Footer from './Footer'
import Hero from './core/Hero'
import TopNav from './core/TopNav'

import Schedule from './Schedule'
import Leadership from './pages/Leadership'
import AboutMe from './pages/AboutMe'
import Coaching from './pages/Coaching'
import BookClub from './pages/BookClub'
import Workshops from './pages/Workshops'
import TempPage from './pages/TempPage'
import HomePage from './pages/HomePage'
import ConflictManagement from './pages/ConflictManagement'
import ResourcesPage from './pages/Resources'

import './App.scss'

export default function App() {
  return (
    <>
      <TopNav />
      <Hero
        title="Jaime Sims McBride Coaching"
        subTitle="love . your . story"
      />
      <div className="bg-main py-10">
        <div className="mx-auto max-w-7xl">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/leadership" element={<Leadership />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/coaching" element={<Coaching />} />
            <Route
              path="/conflict-management"
              element={<ConflictManagement />}
            />
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/book-club" element={<BookClub />} />
            <Route path="/workshops" element={<Workshops />} />
            {/* <Route
              path="/workshops/defining-enough"
              element={<DefiningEnoughWorkshop />}
            /> */}
            <Route path="*" element={<TempPage />} />
          </Routes>
        </div>
      </div>
      {/* <div className="bg-main pb-10">
        <Newsletter />
      </div> */}
      <Footer />
    </>
  )
}
