import cn from 'classnames'
import './Footer.scss'
import AwesomeIcon from './core/AwesomeIcon'
import footerSubtitle from './images/footer2.svg'

export default function Footer() {
  const year = new Date().getFullYear()
  const classes = cn('link', 'mr-2')
  return (
    <footer>
      <div className="flex justify-center text-brand-600">
        <img
          src={footerSubtitle}
          className="max-w-xs md:max-w-md lg:max-w-2xl mb-5"
          alt="Hear yourself think, explore you"
        />
      </div>
      <div>
        <a
          href="https://www.facebook.com/profile.php?id=100084690855662"
          className={classes}
          target="_blank"
        >
          <AwesomeIcon prefix={'fab'} icon={'facebook'} />
        </a>
        <a
          href="https://www.instagram.com/holistichumanalignment/"
          className={classes}
          target="_blank"
        >
          <AwesomeIcon prefix={'fab'} icon={'instagram'} />
        </a>
        <a
          href="https://www.linkedin.com/in/jaime-sims-mcbride-9b9b0934/"
          className={classes}
          target="_blank"
        >
          <AwesomeIcon prefix={'fab'} icon={'linkedin'} />
        </a>
      </div>
      <div>
        <p>&copy; Jaime Sims McBride 2016-{year}</p>
      </div>
    </footer>
  )
}
