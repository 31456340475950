import { Link } from 'react-router-dom'

export default function Card({ to, title, image, children }) {
  return (
    <Link
      to={to}
      className="block mt-2 no-underline hover:no-underline text-base text-gray-500"
    >
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-shrink-0">
          <img className="h-48 w-full object-cover" src={image} alt="" />
        </div>
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <p className="text-xl font-semibold text-gray-900">{title}</p>
            {children}
          </div>
        </div>
      </div>
    </Link>
  )
}
