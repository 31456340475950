import Card from '../Card'
import { WorkshopCTAForm } from '../core/ConvertKit'

import handshakeImage from '../images/handshake.jpg'
import leadershipImage from '../images/leadership2.png'
import coachingImage from '../images/coaching.jpg'
import bookLoveImage from '../images/booklove.png'
import resourcesImage from '../images/resources.png'
import workshopsImage from '../images/workshops2.png'

export default function HomePage() {
  const cardsList = [
    {
      to: '/leadership',
      title: 'Effective Leadership',
      image: leadershipImage,
      children: (
        <p>
          Effective leaders get results because they are self-aware and lead
          authentically. They build strong relationships based on mutual respect
          and clear communication. Emotionally intelligent leaders collaborate
          with and develop others, are agile and motivate others to reach
          strategic goals.
        </p>
      ),
    },
    {
      to: '/coaching',
      title: 'Personal Effectiveness Coaching',
      image: coachingImage,
      children: (
        <p>
          Coaching creates awareness. Awareness of personal values and self.
          Awareness of needs. Awareness of judgments. Awareness of personal
          power and the gift Choice. We are each a product of our own belief
          system.
        </p>
      ),
    },
    {
      to: '/conflict-management',
      title: 'Effective Conflict Management',
      image: handshakeImage,
      children: (
        <p>
          Are you exhausted by having the same conversation/s over and over
          again resulting in anger, alienation and confusion? Communication is
          an expression of a need. Sometimes it is difficult to identify, and
          express needs and conflict ensues and there you are back in that old,
          crappy conversation.
        </p>
      ),
    },
    {
      to: '/workshops',
      title: 'Upcoming Workshops',
      image: workshopsImage,
      children: (
        <p>
          Personal and Professional Effectiveness workshops are open to anyone
          and offered throughout the year.
        </p>
      ),
    },
    {
      to: '/book-club',
      title: 'Virtual Book Club',
      image: bookLoveImage,
      children: (
        <p>
          Virtual Book Clubs will be held throughout the year virtually via Zoom
          (camera on not required). Participate in one session or all of them.
          The only cost is the cost of the book. Stay informed about books we
          are reading and club meeting dates when you subscribe to my email
          list. Like coaching, your email will be confidential always.
        </p>
      ),
    },
    {
      to: '/resources',
      title: 'Resources for Personal and Professional Effectiveness',
      image: resourcesImage,
      children: (
        <p>
          Downloadable resources, book and podcast recommendations, and anything
          else I think you may find helpful. Browse around and help yourself to
          anything. I do take requests, so if there is something you would like
          to see here, send me an email.
        </p>
      ),
    },
    // {
    //   to: '/accreditation',
    //   title: 'Accredit a Training Program',
    //   image: accreditImage,
    // },
  ]
  const cards1 = cardsList.map((c, idx) => <Card key={idx} {...c} />)
  return (
    <>
      <div className="grid gap-8 px-4 sm:px-6 xl:px-0 lg:grid-cols-2">
        {cards1}
      </div>
      <WorkshopCTAForm
        headingText="Stay Connected, sign up for my mailing list!"
        children={null}
      />
    </>
  )
}
