import ConvertKitForm from 'convertkit-react'
import './ConvertKit.scss'

const WORKSHOP_FORM_ID = 3911373

const defaultConfig = {
  formId: WORKSHOP_FORM_ID,
  template: 'mills',
  newTab: true,
  headingText: 'Want to know when new workshops are scheduled?',
  disclaimerText:
    'Like coaching, your email address is confidential and I will not share it with anyone. Unsubscribe at any time.',
  emailPlaceholder: 'Enter an email address',
  submitText: 'Subscribe',
  className: 'ck-fm',
  buttonBackground: '#667c30',
  buttonColor: '#ffffff',
  children: <p>Sign up for my mailing list!</p>,
}

export function WorkshopCTAForm(props) {
  const config = {
    ...defaultConfig,
    ...props,
  }

  return (
    <div className="mt-10 max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden">
      <ConvertKitForm {...config} />
    </div>
  )
}
