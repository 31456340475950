import PageTitle from './PageTitle'

function PricingOption({ text }) {
  return (
    <li className="flex space-x-3">
      <svg
        className="flex-shrink-0 h-5 w-5 text-brand-600"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
      <span className="text-sm text-gray-500">{text}</span>
    </li>
  )
}

export function PricingSection({
  title,
  subtitle,
  cost,
  something,
  buttonText,
  buttonLink,
  options,
  optionsTitle = "What's Included",
}) {
  const items = options.map((text, idx) => (
    <PricingOption key={idx} text={text} />
  ))
  return (
    <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
      <div className="p-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900">{title}</h2>
        <p className="mt-4 text-sm text-gray-500">{subtitle}</p>
        <p className="mt-8">
          <span className="text-4xl font-extrabold text-gray-900">{cost}</span>
          <span className="text-base font-medium text-gray-500">
            {something}
          </span>
        </p>
        <a
          href={buttonLink}
          className="mt-8 block w-full bg-brand-600 border rounded-md py-2 text-sm font-semibold text-gray-50 text-center hover:bg-brand-500 hover:text-gray-50 hover:no-underline"
        >
          {buttonText}
        </a>
      </div>
      <div className="pt-6 pb-8 px-6">
        <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
          {optionsTitle}
        </h3>
        <ul role="list" className="mt-6 space-y-4">
          {items}
        </ul>
      </div>
    </div>
  )
}

export default function PricingTable({
  title = 'Leadership Development Offerings',
  subTitle = 'I always start with a complementary discussion to introduce myself and learn more about your needs.',
  groupOptions,
}) {
  const list = groupOptions.map(options => (
    <PricingSection key={options.title} {...options} />
  ))
  return (
    <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:flex-col sm:align-center">
        <PageTitle text={title} />
        <p className="mt-5 text-xl text-gray-500 sm:text-center">{subTitle}</p>
      </div>
      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
        {list}
      </div>
    </div>
  )
}
