import aboutMe from 'bundle-text:./about_me.md'
import Markdown from 'marked-react'
import ScheduleCta from '../core/ScheduleCta'
import headshot from '../images/headshot1.png'
import renderer from '../renderer'
import PageTitle from './PageTitle'

export default function AboutMe() {
  return (
    <div className="px-5">
      <PageTitle text="About Me" />
      <div>
        <img className="h-64 rounded-full mx-auto" src={headshot} alt="" />
      </div>
      <Markdown value={aboutMe} renderer={renderer} />
      <ScheduleCta />
    </div>
  )
}
