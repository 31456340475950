import cn from 'classnames'
import { createElement } from 'react'

let elementId = 0
let fontSize = 'text-lg'

function h(el, children, props) {
  const elProps = {
    key: `marked-react-render-${elementId}`,
  }

  elementId += 1
  return createElement(el, { ...props, ...elProps }, children)
}

const renderer = {
  heading(children, level) {
    let text = level == 1 ? 'text-2xl' : 'text-xl'
    let styles = cn('font-medium leading-tight mt-4', 'mb-4', text)
    return h(`h${level}`, children, { className: styles })
  },
  paragraph(children) {
    let styles = cn('mb-6', fontSize)
    return h('p', children, { className: styles })
  },
  blockquote(children) {
    let styles = cn(
      'px-2',
      'py-0.5',
      'border-brand-500',
      'border-l-8',
      'rounded-lg',
      'text-xl',
      'bg-brand-100',
      'text-brand-600',
      'align-middle',
      'italic'
    )
    return h('blockquote', children, { className: styles })
  },
  list(children) {
    let styles = cn('list-disc', 'm-6')
    return h('ul', children, { className: styles })
  },
  listItem(children) {
    let styles = cn(fontSize)
    return h('li', children, { className: styles })
  },
  image(href, title, text) {
    return h('img', { src: href })
  },
}

export default renderer
