import effectiveLeadership from 'bundle-text:./effective_leadership.md'
import Markdown from 'marked-react'
import ScheduleCta from '../core/ScheduleCta'
import renderer from '../renderer'
import PageTitle from './PageTitle'
import PricingTable from './PricingTable'

function EmailMe() {
  return (
    <>
      <h2 className="text-5xl font-extrabold text-gray-900 sm:text-center mb-8">
        Don't see what you are looking for?
      </h2>
      <p className="text-center text-xl">
        I customize programs to fit the needs of individuals and/or
        organizations. Email me with your ideas or schedule time with me to
        discuss.
      </p>
    </>
  )
}

const leadershipOptions = [
  {
    title: 'Complementary Consultation Session',
    subtitle: 'A 90 minute individual consultation to discuss options',
    cost: 'FREE',
    something: '',
    buttonText: 'Schedule Free Consutlation',
    buttonLink: 'https://calendly.com/exploreyou/coaching-introductory-call',
    options: [
      'An individual 1-on-1 consultation session for perspective clients',
    ],
  },
  {
    title: 'One-One Coaching for Individuals',
    subtitle: 'A single 60 minute individual coaching session',
    cost: '$75',
    something: '/hr',
    buttonText: 'Schedule Free Consutlation',
    buttonLink: 'https://calendly.com/exploreyou/coaching-introductory-call',
    optionsTitle: 'Individual coaching sessions provide a space to:',
    options: [
      'Increase Self Awareness (the key to authentic, effective leadership)',
      'Increase personal effectiveness',
      'Remove inner roadblocks and barriers',
      'Lead authentically',
      'Create an action plan to fulfill individual and business needs',
      'Accountability',
      'Explore ideas and approaches to professional challenges',
      'Explore any issue in a confidential and non-judgmental space',
    ],
  },
  {
    title: 'Group Coaching Session',
    subtitle: '60 minute group coaching session, 2-8 participants',
    cost: '$45',
    something: '/hr per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/exploratory-discussion-group-and-or-individual-coaching',
    optionsTitle: 'Group Coaching Sessions provide a space to:',
    options: [
      'Increase Self Awareness (the key to authentic, effective leadership)',
      'Increase personal and group effectiveness',
      'Remove roadblocks and barriers',
      'Work from authenticity',
      'Create an action plan to fulfill individual and business needs',
      'Accountability',
      'Explore ideas and approaches to professional challenges',
      'Explore any group issue in a confidential and non-judgmental space',
    ],
  },
  {
    title: 'Leadership Consulting',
    subtitle: 'Schedule a free consultation to discuss options.',
    cost: '$100',
    something: '/hr',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/customized-workshops-w-coaching-discussion',
    optionsTitle: 'Leadership consulting is a wonderful option if:',
    options: [
      'You are setting up a leadership team for the first time',
      'Promoting new leaders',
      'Experiencing leadership and/or productivity issues',
      'Address morale issues',
      'Help you determine what you need from your leaders and how to get those needs met',
      'Includes individual and/or group coaching',
      'Customized individually to your needs!',
    ],
  },
  {
    title:
      'Leadership Skills Training with Coaching - Individuals and Teams / Groups',
    subtitle:
      'Leadership and management skills training, customized to your needs',
    cost: '$300',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/customized-workshops-w-coaching-discussion',
    options: [
      '2 hours of live virtual training on the leadership or management skill of your choice. Training will be in 2 sessions of 1 hour each.',
      '4 hours of individual coaching per participant - 2 after 1st training session and 2 following the final training session.',
      'Action plan for integrating new knowledge in day-to-day processes.',
      'Customized individually to your needs!',
    ],
  },
  {
    title: 'Energy Leadership Index Assessment with Leadership Focus',
    subtitle: 'For Individuals and Teams / Groups',
    cost: '$250',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/assessment-eli-eq-disc-discussion',
    options: [
      'Take Energy Leadership Index Assessment to determine how you approach leadership',
      'Debrief of the assessment (Approximately 2 hours)',
      '2 Individual Coaching Sessions for each participant (60 minutes each)',
      '1 hour of live virtual training and discussion about a leadership concept or skill.',
    ],
  },
  {
    title: 'Emotional Intelligence Assessment with Leadership Focus',
    subtitle:
      'Taking FLight with EQ (Emotional Intelligence) Assessment, plus DISC Assessment, with Coaching',
    cost: '$250',
    something: '/per person',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/assessment-eli-eq-disc-discussion',
    options: [
      'Take Emotional Intelligence Assessment to determine your EQ (emotional intelligence) approach.',
      'Individual debrief of the assessment (Approximately 1 hour per participant)',
      '2 Individual Coaching Sessions for each participant (60 minutes each)',
      '1 hour of live virtual training about integrating new knowledge into day to day processes.',
    ],
  },
  {
    title: 'Customized Leadership Development Program',
    subtitle: 'Group or individual',
    cost: '',
    something: 'Schedule a free consultation to discuss options.',
    buttonText: 'Schedule Free Consutlation',
    buttonLink:
      'https://calendly.com/exploreyou/customized-workshops-w-coaching-discussion',
    options: ['Customized individually to your needs!'],
  },
]

export default function Leadership() {
  return (
    <div className="px-5">
      <PageTitle text="Become a More Effective Leader" />
      <Markdown value={effectiveLeadership} renderer={renderer} />
      <PricingTable groupOptions={leadershipOptions} />
      <ScheduleCta callToAction="Want more effective leaders?" />
      {/* <EmailMe /> */}
    </div>
  )
}
